<template>
  <div class="content">
    <a-page-header style="padding: 0" title="Administrators" />
    <a-row
      type="flex"
      justify="space-around"
      align="middle"
      class="content"
      style="height: calc(100vh - 300px)"
    >
      <a-col> <h1 style="font-size: 100px">Workstats-admin</h1> </a-col>
    </a-row>
    <router-view v-slot="{ Component }">
      <transition name="slide-left" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script>
import { onMounted, onUnmounted, reactive, toRefs } from "vue";
import { useRouter } from "vue-router";

export default {
  components: {},
  setup() {
    const router = useRouter();
    const state = reactive({
      data: [],

      siderData: {
        title: router.currentRoute.value.name,
        list: [],
      },
      siderStatus: false,
      isLoading: true,
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/style/mixins";

.content {
  background-color: $color-white;
  padding: 15px;
  overflow: auto;
}
.footer {
  text-align: center;
}
.table {
  margin-top: 15px;
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(30px);
  opacity: 0;
}
</style>
